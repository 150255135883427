@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.photos {
  display: grid;
  gap: 1rem 1rem;
  grid-template-columns: 1fr;

  &.mini {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    &.loading {
      opacity: 0;
    }
  }

  @include is_larger_than($sm-breakpoint) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include is_larger_than($md-breakpoint) {
    &.mini {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
}

.photo {
  @include themed() {
    background-color: t($color-background-container);
  }
}

.tile {
  line-height: 0;
  font-size: 0;

  @include card($color-background-container, true);

  img {
    @include hover_scale(1.04);
  }
}
