@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.homepage_about {
  border-radius: $roundness;
  padding: 1rem 1rem 0 1rem;

  .github_calendar {
    margin: 2.5rem 0 0;
    display: none;

    @include is_larger_than($sm-breakpoint) {
      display: block;
    }
  }

  @include is_larger_than($sm-breakpoint) {
    padding: 1.5rem 3rem;

    @include themed() {
      border: 2px dashed t($color-quaternary);
    }
  }

  .theme_buttons {
    margin-left: 0.75rem;
  }

  br + p {
    margin-top: 0rem;
  }

  strong {
    font-weight: bold;
  }

  a {
    @include interactive_secondary();
  }
}

.bottom_half {
  display: grid;
  min-height: 9rem;

  .contributions {
    height: 100%;
    display: none;
  }

  @include is_larger_than($md-breakpoint) {
    grid-gap: 2rem;
    grid-template-columns: 4fr 3fr;

    .contributions {
      display: block;
    }
  }
}
