@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.homepage_image {
  @include card($color-background-container, true);

  & > .homepage_image_svg {
    transition: opacity 200ms ease-in, stroke-dashoffset 200ms ease-in-out;
    stroke-dasharray: 265;
    stroke-dashoffset: 265;
    opacity: 0;
  }

  @media (hover: hover) {
    &:hover,
    &:active {
      & > .homepage_image_svg {
        transition: opacity 300ms ease-in, stroke-dashoffset 500ms ease-in-out;
        opacity: 0.8;
        stroke-dashoffset: 0;
      }
    }
  }
}
