@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.frame {
  @include card($color-black, true);

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
