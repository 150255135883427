@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.subnavigation {
  @include card();

  margin: 0 0 2rem 0;
  padding: 0;
  position: relative;
  display: grid;

  @include is_larger_than($sm-breakpoint) {
    grid-template-columns: 1fr auto;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    &:nth-child(2) {
      display: flex;
      flex-direction: row-reverse;
    }

    @include is_larger_than($sm-breakpoint) {
      flex-direction: row;
    }

    & > li {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;

      @include is_larger_than($sm-breakpoint) {
        width: min-content;
      }

      & > * {
        width: 100%;
        line-height: 2rem;
        padding: 0 1.25rem;
      }
    }

    &:first-child > li {
      @include interactive();

      & > a {
        display: inline-block;
        position: relative;
        padding: 0 1.25rem;
        white-space: nowrap;
        text-decoration: none;

        @include themed() {
          color: t($color-quinary);
        }
      }

      @include themed() {
        &.current {
          background: t($color-quinary);

          a {
            color: t($color-background-default);
          }
        }
      }
    }

    &:nth-child(2) {
      @include themed() {
        padding: 0.5rem 0;
        margin-top: 0.5rem;
        border-top: 2px solid t($color-secondary);

        @include is_larger_than($sm-breakpoint) {
          padding: 0;
          margin: 0;
          border: none;
        }
      }
    }
  }
}
