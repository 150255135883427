@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.projects {
  display: grid;
  gap: 1rem 1rem;
  grid-template-columns: 1fr;

  @include is_larger_than($sm-breakpoint) {
    grid-template-columns: 1fr 1fr;
  }

  @include is_larger_than($md-breakpoint) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.see_more {
  @include is_larger_than($sm_breakpoint) {
    grid-column: span 2;
  }

  @include is_larger_than($md-breakpoint) {
    grid-column: span 1;
  }
}
