@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.paragraph {
  display: flex;

  .icon {
    margin: 0 0.6rem 0 0;
    align-self: center;

    @include themed() {
      color: t($color-secondary);
    }
  }

  .content {
    flex: 1;
  }
}
