@use "../../shared.scss" as *;

.frame {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > svg:last-child {
    position: relative;
    z-index: -1;
  }

  & > *:first-child {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &.hidden > *:first-child {
    display: none;
  }

  &:not(.hidden) > *:first-child {
    :global {
      animation: fadein ease-in 300ms;
    }
  }

  .fullscreen_button {
    position: absolute;
    width: 2rem;
    height: 2rem;
    inset: 0.5rem 0.5rem auto auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover > .fullscreen_button {
    opacity: 0.8;
  }
}
