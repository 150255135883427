@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.homepage {
  display: grid;
  gap: 1rem 1rem;
  grid-template-columns: 1fr;

  @include is_larger_than($sm-breakpoint) {
    grid-template-columns: 5fr 8fr;
  }

  .preview {
    margin-top: 1rem;

    & section > article {
      margin: 0;
    }

    @include is_larger_than($sm-breakpoint) {
      margin-top: 1.5rem;
      grid-column: span 2;
    }
  }
}
