@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;

  @include card();
  @include text_spacing();

  .svg {
    width: calc(100% - 2rem);
    margin: 1rem;
    height: 5rem;

    @include themed() {
      background: t($color-primary);
    }
  }

  .title {
    display: flex;
    padding: 0 0.25rem;

    h4 {
      font-size: 0.95em;
    }
  }

  header {
    flex: 1 0;
    font-size: 1.2rem;

    h4 {
      margin: 0.5rem 0;
    }
  }

  footer {
    flex: 0 0 auto;
    font-size: 0.9rem;
    padding: 0.7rem 0;
  }

  p {
    flex: 0 0 100%;
    font-size: 0.9rem;
    margin-top: 0;
    padding: 1rem 0 0 0.5rem;
  }
}

.card_clickable {
  @include interactive();
}
