@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.icon_button {
  height: $navbar-height;
  width: $navbar-height;
  border: none;
  background-color: initial;
  padding: 0;

  @include interactive(false);

  & > svg {
    width: 60%;
    height: 60%;
    padding: 20%;
    stroke-width: 3px;

    @include themed() {
      stroke: t($color-primary);
    }
  }
}
