@use "../../shared.scss" as *;

.image_svg_overlay {
  position: relative;

  .svg_overlay {
    position: absolute;
    inset: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
}
