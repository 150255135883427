@use "../../shared.scss" as *;
@use "../../themed.scss" as *;

.projects_figrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @include is_larger_than($sm-breakpoint) {
    grid-template-columns: 1fr 1fr;
  }

  & > div:first-child {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;

    @include themed() {
      border: 2px solid t($color-secondary);
    }
  }

  & > div:last-child {
    padding: 1rem;

    & > div {
      height: 100%;
      width: 100%;
    }

    @include themed() {
      background: t($color-background-container);
      border: 2px solid t($color-secondary);
    }
  }

  h4 {
    margin-top: 1rem;
    text-align: center;
  }

  a {
    @include interactive_secondary();

    white-space: nowrap;
  }
}
